import axios from "axios";
import { getToken, destroyToken } from "./auth/token";

class PrivateApi {
  constructor() {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
    axios.defaults.headers.common["Accept"] = `application/json`;
    axios.defaults.headers.common["Content-Type"] = `application/json`;
  }

  async get(slug = "") {
    try {
      const response = await axios.get(`${slug}`);

      return response;
    } catch (error) {
      if (error.response.status === 401) {
        destroyToken();
      }

      return error.response;
    }
  }

  async post(slug, params, responseType = "json") {
    try {
      const response = await axios.post(`${slug}`, params, {
        responseType: responseType,
      });

      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        destroyToken();
      }

      return error.response;
    }
  }

  async update(slug, params) {
    try {
      const response = await axios.put(`${slug}`, params);

      return response;
    } catch (error) {
      if (error.response.status === 401) {
        destroyToken();
      }

      return error.response;
    }
  }

  async formUpdate(slug, params) {
    try {
      const response = await axios.post(`${slug}`, params);

      return response;
    } catch (error) {
      if (error.response.status === 401) {
        destroyToken();
      }

      return error.response;
    }
  }

  async delete(slug) {
    try {
      const response = await axios.delete(`${slug}`);

      return response;
    } catch (error) {
      if (error.response.status === 401) {
        destroyToken();
      }

      return error.response;
    }
  }

  redirectToUrlWithPost = (url, postData) => {
    const form = document.createElement("form");
    form.method = "post";
    form.action = url;
    form.target = "_blank";

    for (const key in postData) {
      if (postData.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = postData[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  handleClickRedirect = (url, data) => {
    const fullurl = process.env.REACT_APP_BASE_URL + url;

    this.redirectToUrlWithPost(fullurl, data);
  };
}

const privateApi = new PrivateApi();

export default privateApi;
