import moment from "moment";

export function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes}`;
}

export function dateFormat(date, type) {
  switch (type) {
    case 1:
      return moment(date).locale("ja").format("YYYY-MM-DD (ddd) HH:mm");

    case 2:
      return moment(date).locale("ja").format("YYYY-MM-DD");

    default:
      return;
  }
}
