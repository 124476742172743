// note praveen: write the styles in a style sheet

function TableCellOverflowDiv({ title, width, children }) {
  return (
    <div
      title={title}
      style={{
        width,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {children}
    </div>
  );
}

export default TableCellOverflowDiv;
