import { dateFormat } from "src/utils/formatdate";

function MyInputDate({ rawValue, onChange, readOnly, ...props }) {
  // note praveen initialize the rawValue to an empty string when its passed so that this component doesnt have to worry about it
  const value = rawValue ? dateFormat(rawValue, 2) : "";

  const handleChange = e => {
    const date = dateFormat(e.target.value, 2);
    e.target.value = date;
    onChange(e);
  };

  return (
    <input
      type="date"
      className="form-control"
      value={value}
      onChange={handleChange}
      readOnly={readOnly}
      max="3090-12-31"
      {...props}
    />
  );
}

export default MyInputDate;
