import { useEffect, useState } from "react";

function MyInputNumberText({
  rawValue,
  onChange,
  selectTextOnFocus,
  displayLocaleString,
  readOnly,
  ...props
}) {
  let value = rawValue;

  if (!isNaN(Number(value)) && displayLocaleString && readOnly) {
    value = Number(value).toLocaleString("en");
  }

  const handleFocus = e => {
    if (selectTextOnFocus) {
      e.target.select();
    }
  };

  return (
    <input
      type="text"
      className="form-control"
      value={value}
      onChange={onChange}
      onFocus={handleFocus}
      readOnly={readOnly}
      {...props}
    />
  );
}

export default MyInputNumberText;
