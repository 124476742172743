import moment from "moment";
import ActualValue from "../../common/ActualValue";
import React from "react";

const RidingDataTable = ({
  ridingData,
  tableRef,
  idView,
  telView,
  ridingReports,
  contentRef,
}) => {
  const dummyRow = [
    ...Array(telView && idView ? 8 : telView || idView ? 7 : 6).keys(),
  ].map((i, j) => <td>&nbsp;</td>);

  const riderRowCount = ridingReports
    ? ridingReports.reduce((acc, curr) => {
        const riderLength = curr.riders.length;
        if (riderLength) {
          acc += 1 + (riderLength === 1 ? 2 : riderLength);
        }
        return acc;
      }, 0)
    : 0;

  return (
    <div ref={contentRef} className="table-responsive" id="ridingTable">
      <div className="p-3 mb-3 light-border">
        <div className="bg-light p-2">
          <div className="row ride_headline">
            <h5 className="col-4">
              {ridingData?.class_name} - {ridingData?.time}
            </h5>
            <h5 className="col-4 text-center">{ridingData?.day_week} 曜日</h5>
            <h5 className="col-4 text-end">{ridingData?.bus_name} コース</h5>
          </div>
        </div>

        <div className="table-responsive">
          <table
            className="mb-0 table table-bordered mt-2 custom-tbl2 align-middle"
            ref={tableRef}
          >
            <thead style={{ marginTop: "10px" }}>
              <tr>
                <th>バス停</th>
                {idView && <th>No</th>}
                <th>名前</th>
                <th>フリガナ</th>
                <th>往</th>
                <th>復</th>
                {telView && <th>TEL</th>}
                <th className="truncated-text">備考</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "15px" }}>
              {ridingReports &&
                ridingReports.map((stopage, stopageIndex) => (
                  <React.Fragment key={stopageIndex}>
                    {stopage.riders.length > 0 && (
                      <>
                        {stopage.riders.map((rider, riderIndex) => (
                          <>
                            <React.Fragment
                              key={`${stopageIndex}-${riderIndex}`}
                            >
                              <tr>
                                {riderIndex === 0 && (
                                  <td
                                    style={{ verticalAlign: "baseline" }}
                                    rowSpan={
                                      stopage.riders.length === 1
                                        ? 2
                                        : stopage.riders.length
                                    }
                                  >
                                    <strong style={{ fontSize: "20px" }}>
                                      {stopage.stopage_name}
                                    </strong>
                                    <br />
                                    {stopage.time}
                                  </td>
                                )}

                                {idView && <td>{ActualValue(rider.no)}</td>}

                                <td>{rider.name}</td>
                                <td>{rider.name_furigana}</td>
                                <td className="text-center">
                                  {rider.start ? "⭕️" : "❌"}
                                </td>
                                <td className="text-center">
                                  {rider.return ? "⭕️" : "❌"}
                                </td>

                                {telView && <td>{rider.tel}</td>}

                                <td className="truncated-text">
                                  {rider.note?.length > 8
                                    ? rider.note?.substring(0, 15) + "..."
                                    : rider.note}{" "}
                                </td>
                              </tr>
                              {stopage.riders.length === 1 && (
                                <tr className="riding-list-hacky-fix">
                                  {idView && <td>{ActualValue(rider.no)}</td>}

                                  <td>{rider.name}</td>
                                  <td>{rider.name_furigana}</td>
                                  <td className="text-center">
                                    {rider.start ? "⭕️" : "❌"}
                                  </td>
                                  <td className="text-center">
                                    {rider.return ? "⭕️" : "❌"}
                                  </td>

                                  {telView && <td>{rider.tel}</td>}

                                  <td className="truncated-text">
                                    {rider.note?.length > 8
                                      ? rider.note?.substring(0, 15) + "..."
                                      : rider.note}{" "}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          </>
                        ))}

                        <tr>
                          <td>&nbsp;</td>

                          {idView && <td>&nbsp;</td>}

                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td className="text-center">
                            <strong>
                              {stopage.riders.reduce(
                                (total, rider) => total + (rider.start ? 1 : 0),
                                0
                              )}
                            </strong>
                          </td>
                          <td className="text-center">
                            <strong>
                              {stopage.riders.reduce(
                                (total, rider) =>
                                  total + (rider.return ? 1 : 0),
                                0
                              )}
                            </strong>
                          </td>
                          {telView && <td>&nbsp;</td>}
                          <td>&nbsp;</td>
                        </tr>
                      </>
                    )}
                  </React.Fragment>
                ))}

              {Array.from({
                length:
                  17 +
                  (riderRowCount > 17
                    ? 23 * (Math.ceil(riderRowCount / 17) - 1)
                    : 0) -
                  riderRowCount, // 17 + 6 = 23 where 6 is the height of margin portion and bottom portion
              }).map((_, index, arr) => {
                if (index === 10) {
                  return (
                    <tr className="page-break" key={`extraRow-${index}`}>
                      {dummyRow}
                    </tr>
                  );
                }
                return <tr key={`extraRow-${index}`}>{dummyRow}</tr>;
              })}

              <tr>
                <td colSpan={idView ? 4 : 3}></td>
                <td className="text-center">
                  <strong>往</strong>
                </td>
                <td className="text-center">
                  <strong>復</strong>
                </td>
                <td colSpan={telView ? 2 : 1}></td>
              </tr>

              <tr>
                <td colSpan={idView ? 4 : 3}></td>
                <td className="text-center">
                  <strong>
                    {ridingReports?.reduce(
                      (total, stopage) =>
                        total +
                        stopage.riders.reduce(
                          (riderTotal, rider) =>
                            riderTotal + (rider.start ? 1 : 0),
                          0
                        ),
                      0
                    )}
                  </strong>
                </td>
                <td className="text-center">
                  <strong>
                    {ridingReports?.reduce(
                      (total, stopage) =>
                        total +
                        stopage.riders.reduce(
                          (riderTotal, rider) =>
                            riderTotal + (rider.return ? 1 : 0),
                          0
                        ),
                      0
                    )}
                  </strong>
                </td>
                <td colSpan={telView ? 2 : 1}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="mx-2">
        印刷日: {moment(new Date()).format("YYYY-MM-DD")}
      </div>
    </div>
  );
};

export default RidingDataTable;
