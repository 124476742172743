import { useEffect, useRef } from "react";

const SpecialInputForStudentPage = ({ onChange, value }) => {
	const ref = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			handleFocus();
		}, 1000)
	}, []);

	const handleFocus = () => ref.current.select();

	return (
		<input
			className="form-control"
			type="text"
			placeholder="会員番号／氏名／フリガナで検索"
			value={value}
			onChange={onChange}
			style={{ padding: "3px" }}
			ref={ref}
			onFocus={handleFocus}
		/>
	)
}

export default SpecialInputForStudentPage;